import getters from './getters';

describe('getters', () => {
  describe('exportLength', () => {
    it('should return 3 if state.endTime.valueOf() is 5000 and state.beginTime.valueOf() is 2000', () => {
      const STATE = {
        endTime: {
          valueOf: () => 5000,
        },
        beginTime: {
          valueOf: () => 2000,
        }
      };

      expect(getters.exportLength(STATE)).toBe(3);
    });
  });
  describe('isExporting', () => {
    it('should return true if state.status is EXPORTING', () => {
      const STATE = {
        status: 'EXPORTING'
      };
      expect(getters.isExporting(STATE)).toBe(true);
    });
    it('should return false if state.status is NORMAL', () => {
      const STATE = {
        status: 'NORMAl'
      };
      expect(getters.isExporting(STATE)).toBe(false);
    });
  });
  describe('isError', () => {
    it('should return true ', () => {
      const STATE = {
        status: 'ERROR'
      };
      expect(getters.isError(STATE)).toBe(true);
    });
    it('should return false', () => {
      const STATE = {
        status: 'NORMAL'
      };
      expect(getters.isError(STATE)).toBe(false);
    });
  });
});
